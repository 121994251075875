import { useMemo } from 'react'
import { useDataOriginalValue, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let rulesets_original = useDataOriginalValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })
  let rulesets_current = useDataValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!rulesets_original?.length || !rulesets_current) {
      return data
    }

    let original_value = mapDefaultPaymentPlans(rulesets_original)
    let current_value = mapDefaultPaymentPlans(rulesets_current)

    let add_changes = addChanges(current_value)
    let delete_changes = deleteChanges(original_value, current_value)
    let update_changes = updateChanges(original_value, current_value)

    return {
      has_changes: Boolean(
        add_changes.length || delete_changes.length || update_changes.length
      ),
      add_changes,
      delete_changes,
      update_changes,
    }
  }, [data, rulesets_original, rulesets_current])
}

function mapDefaultPaymentPlans(values) {
  return values
    .map(value =>
      value.default_payment_plans.map((item, index) => ({
        ...item,
        organization_id: value._id,
        order: index,
      }))
    )
    .flat()
}

function addChanges(current) {
  return current.filter(cr_item => !cr_item.id)
}

function deleteChanges(original, current) {
  return original
    .map(or_item => or_item.id)
    .filter(or_id => or_id && !current.some(cr_item => cr_item.id === or_id))
}

function updateChanges(original, current) {
  return current
    .map(cr_item => {
      if (!cr_item.id) return null

      let or_item = original.find(or => or.id === cr_item.id)
      if (!or_item) return null

      let discount_changes = discountChanges(
        or_item.discounts,
        cr_item.discounts
      )

      return or_item.organization_id !== cr_item.organization_id ||
        or_item.name !== cr_item.name ||
        or_item.description !== cr_item.description ||
        or_item.downpayment !== cr_item.downpayment ||
        or_item.downpayment_type !== cr_item.downpayment_type ||
        or_item.length !== cr_item.length ||
        or_item.length_type !== cr_item.length_type ||
        or_item.frequency !== cr_item.frequency ||
        or_item.order !== cr_item.order ||
        discount_changes.has_changes
        ? { ...cr_item, discount_changes }
        : null
    })
    .filter(Boolean)
}

function discountChanges(original, current) {
  let add_changes = current.filter(cr_item => !cr_item.id)

  let delete_changes = original
    .map(or_item => or_item.id)
    .filter(or_id => !current.some(cr_item => cr_item.id === or_id))

  let update_changes = current.filter(cr_item => {
    if (!cr_item.id) return false
    let { discount_id, value, type, stack_with_other_discounts } =
      original.find(or_item => or_item.id === cr_item.id)
    return (
      discount_id !== cr_item.discount_id ||
      value !== cr_item.value ||
      type !== cr_item.type ||
      stack_with_other_discounts !== cr_item.stack_with_other_discounts
    )
  })

  return {
    has_changes: Boolean(
      add_changes.length || delete_changes.length || update_changes.length
    ),
    add_changes,
    delete_changes,
    update_changes,
  }
}
