import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

export default function useDataTransform(props, data) {
  let name = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'name',
  })
  let treatment_plan = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs.tx_plan',
  })

  return useMemo(
    () => ({
      ...data,
      name,
      treatment_plan_id: treatment_plan?.id,
      treatment_plan_name: treatment_plan?.name,
      treatment_plan_template_id: treatment_plan?.tx_plan_template?.id,
    }),
    [data, name]
  )
}
