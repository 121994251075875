import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let tx_card = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    if (!value.name) return true

    let mutationResponse = await executeMutation({
      tx_card_id: tx_card._id,
      name: value.name,
      tx_updates: [
        {
          where: { _id: { _eq: tx_card.txs._id } },
          _set: { name: value.name },
        },
      ],
      treatment_plan_id: value.treatment_plan_id ?? -1,
      treatment_plan_name: value.treatment_plan_name ?? '',
      update_treatment_plan:
        value.treatment_plan_name !== originalValue.treatment_plan_name &&
        value.change_treatment_plan === true,
    })

    if (mutationResponse.error) {
      return notify(
        notifyError(`Cannot rename the treatment card. Please try again.`)
      )
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
