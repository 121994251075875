import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let organization_id = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs.organization.id',
  })

  return {
    variables: {
      organization_id,
    },
    pause: !organization_id,
  }
}
