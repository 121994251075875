import {
  useDataIsSubmitting,
  useDataOriginalValue,
  useDataValue,
} from 'Simple/Data'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'rename',
    viewPath: props.viewPath,
  })

  let originalValue = useDataOriginalValue({
    context: 'rename',
    viewPath: props.viewPath,
  })

  let value = useDataValue({
    context: 'rename',
    viewPath: props.viewPath,
  })

  return (
    isSubmitting ||
    (value.name === originalValue.name &&
      value.treatment_plan_name === originalValue.treatment_plan_name)
  )
}
