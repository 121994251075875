import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let treatment_plan_template_id = useDataValue({
    context: 'rename',
    path: 'treatment_plan_template_id',
    viewPath: props.viewPath,
  })
  let treatment_plan_name = useDataValue({
    context: 'rename',
    path: 'treatment_plan_name',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data?.length) return []

    return data.map(item => {
      if (
        item.id === treatment_plan_template_id &&
        item.text !== treatment_plan_name
      ) {
        item.text = treatment_plan_name
      }

      return item
    })
  }, [data, treatment_plan_name, treatment_plan_template_id])
}
