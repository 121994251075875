// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Treatment/Content/Actions/Content/Rename/Content/TreatmentPlanName/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Treatment_Content_Actions_Content_Rename_Content_TreatmentPlanName_Content($organization_id: numeric!) {
  vaxiom_tx_plan_templates(
    where: {
      deleted: { _eq: false }
      organization_id: { _eq: $organization_id }
      _or: [
        { tx_plan_group_id: { _is_null: true } }
        { tx_plan_group: { deleted: { _eq: false } } }
      ]
    }
    order_by: { name: asc_nulls_last }
  ) {
    id
    text: name
  }
}

`