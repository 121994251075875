import { useMutation } from 'Data/Api.js'
import mutationMarkAsConfirmed from './mutation-mark-as-confirmed.graphql.js'
import mutationCancelAppointment from './mutation-cancel-appointment.graphql.js'
import mutationCheckInPatient from './mutation-check-in-patient.graphql.js'
import mutationMarkAsNoShow from './mutation-mark-as-no-show.graphql.js'
import mutationRollabackAppointmentState from './mutation-rollback-appointment-state.graphql.js'
import mutationSeat from './mutation-seat.graphql.js'
import mutationUnseat from './mutation-unseat.graphql.js'
import mutationCheckout from './mutation-check-out.graphql.js'
import mutationDeleteAppointment from './mutation-delete-appointment.graphql.js'
import mutationSendLink from './mutation-send-link.graphql.js'
import mutationPrintAppointmentSlip from './mutation-print-appointment-slip.graphql.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionMarkAsConfirmed = useDataOnActionMarkAsConfirmed(props, data)
  let onActionCancelAppointment = useDataOnActionCancelAppointment(props, data)
  let onActionCheckInPatient = useDataOnActionCheckInPatient(props, data)
  let onActionMarkAsNoShow = useDataOnActionMarkAsNoShow(props, data)
  let onActionRollbackAppointmentState =
    useDataOnActionRollbackAppointmentState(props, data)
  let onActionSeat = useDataOnActionSeat(props, data)
  let onActionUnseat = useDataOnActionUnseat(props, data)
  let onActionCheckout = useDataOnActionCheckOut(props, data)
  let onActionDeleteAppointment = useDataOnActionDeleteAppointment(props, data)
  let onActionScheduleVia = useDataOnActionScheduleVia(props, data)
  let onActionPrintAppointmentSlip = useDataOnActionPrintAppointmentSlip(
    props,
    data
  )

  return async function onSubmit({ args, ...params }) {
    switch (args.type) {
      case 'markAsConfirmed': {
        return onActionMarkAsConfirmed({ args, ...params })
      }
      case 'cancelAppointment': {
        return onActionCancelAppointment({ args, ...params })
      }
      case 'checkIn': {
        return onActionCheckInPatient({ args, ...params })
      }
      case 'markAsNoShow': {
        return onActionMarkAsNoShow({ args, ...params })
      }
      case 'cancelCheckIn':
      case 'cancelCheckOut':
      case 'cancelSeating':
      case 'cancelUnseating': {
        return onActionRollbackAppointmentState({ args, ...params })
      }
      case 'seat': {
        return onActionSeat({ args, ...params })
      }
      case 'unseat': {
        return onActionUnseat({ args, ...params })
      }
      case 'checkOut': {
        return onActionCheckout({ args, ...params })
      }
      case 'deleteAppointment': {
        return onActionDeleteAppointment({ args, ...params })
      }
      case 'scheduleVia': {
        return onActionScheduleVia({ args, ...params })
      }
      case 'printAppointmentSlip': {
        return onActionPrintAppointmentSlip({ args, ...params })
      }
      default: {
      }
    }

    // Once the action is executed, call the `onClickComplete` function if it exists
    if (typeof props.onClickComplete === 'function') props.onClickComplete()
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionMarkAsConfirmed(props, data) {
  let [, executeMutation] = useMutation(mutationMarkAsConfirmed)
  let [, notify] = useNotifications()

  return async function onActionMarkAsConfirmed({ value }) {
    let mutationResponse = await executeMutation({
      appointment_booking_id: value.appointment_booking.id,
    })

    if (mutationResponse.error) {
      notify(notifyError('Could not confirm appointment'))
      return false
    }

    notify(notifySuccess(`The appointment was confirmed.`))
    return true
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionCancelAppointment(props, data) {
  let [, executeMutation] = useMutation(mutationCancelAppointment)
  let [, notify] = useNotifications()

  return async function onActionCancelAppointment({ value }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem cancelling the appointment. Please try again.`
        )
      )
      return false
    }

    notify(notifySuccess(`The appointment was cancelled successfully.`))
    return true
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionCheckInPatient(props, data) {
  let [, executeMutation] = useMutation(mutationCheckInPatient)
  let [, notify] = useNotifications()

  return async function onActionCheckInPatient({ value }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem checking the patient in. Please try again.`
        )
      )
      return false
    }

    notify(notifySuccess(`The patient was checked in successfully.`))
    return true
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionMarkAsNoShow(props, data) {
  let [, executeMutation] = useMutation(mutationMarkAsNoShow)
  let [, notify] = useNotifications()

  return async function onActionMarkAsNoShow({ value }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem marking the appointment booking as NO_SHOW. Please try again.`
        )
      )
      return false
    }

    notify(notifySuccess(`The appointment booking was marked as no-show`))
    return true
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionRollbackAppointmentState(props, data) {
  let [, executeMutation] = useMutation(mutationRollabackAppointmentState)
  let [, notify] = useNotifications()

  return async function onActionRollbackAppointmentState({ value }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem cancelling the appointment booking state. Please try again.`
        )
      )
      return false
    }

    notify(
      notifySuccess(`The appointment booking state was successfully reverted`)
    )
    return true
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionUnseat(props, data) {
  let [, executeMutation] = useMutation(mutationUnseat)
  let [, notify] = useNotifications()

  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onActionUnseat({ value, args }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
      skip_required_fields: args?.skip_required_fields || false,
    })

    if (mutationResponse.error) {
      notify(
        notifyError('Cannot unseat selected appointment. Please try again.')
      )
      return false
    }

    if (
      mutationResponse.data.vaxiom_appointment_booking_unseat.status ===
      'missing_required_treatment_fields'
    ) {
      setFlowTo(
        normalizePath(
          props.viewPath,
          'Unseat/Content/MissingRequiredTreatmentFieldsDialog/Content'
        )
      )

      return false
    }

    notify(notifySuccess(`The patient was unseated successfully`))
    return true
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionCheckOut(props, data) {
  let [, executeMutation] = useMutation(mutationCheckout)
  let [, notify] = useNotifications()

  return async function onActionCheckout({ value, args }) {
    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
    })

    if (mutationResponse.error) {
      notify(notifyError('Cannot checkout patient. Please try again.'))
      return false
    }

    notify(notifySuccess(`The patient was checked out successfully`))
    return true
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionSeat(props, data) {
  let [, executeMutation] = useMutation(mutationSeat)
  let [, notify] = useNotifications()

  return async function onActionSeat({ value, args }) {
    if (!args.chair_id || !args.provider_id || !args.assistant_id) {
      return true
    }

    let mutationResponse = await executeMutation({
      id: value.appointment_booking.id,
      provider_id: args.provider_id,
      assistant_id: args.assistant_id,
      chair_id: args.chair_id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem seating the patient. Please try again.`
        )
      )
      return false
    }

    notify(notifySuccess(`The patient was seated successfully`))
    return true
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionDeleteAppointment(props, data) {
  let [, executeMutation] = useMutation(mutationDeleteAppointment)
  let [, notify] = useNotifications()

  return async function onActionDeleteAppointment({ value, args }) {
    let mutationResponse = await executeMutation({
      id: value.appointment.id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem deleting the appointment. Please try again.`
        )
      )
      return false
    }

    notify(notifySuccess(`The appointment was deleted successfully.`))
    return true
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionScheduleVia(props, data) {
  let [, executeMutation] = useMutation(mutationSendLink)
  let [, notify] = useNotifications()

  return async function onActionScheduleVia({ value, args }) {
    let { selected_contact_method, custom_instructions } = args
    if (!selected_contact_method) {
      notify(notifyError(`Could not find the selected contact method`))

      return false
    }

    let mutationResponse = await executeMutation({
      appointment_id: value.appointment.id,
      phone:
        selected_contact_method.dtype === 'phone'
          ? selected_contact_method.phone.number
          : null,
      email:
        selected_contact_method.dtype === 'email'
          ? selected_contact_method.email.address
          : null,
      custom_instructions: custom_instructions,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem sending the scheduling link. Please try again.`
        )
      )
      return false
    }

    notify(notifySuccess(`The scheduling link was sent successfully.`))
    return true
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionPrintAppointmentSlip(props, data) {
  let [, executeMutation] = useMutation(mutationPrintAppointmentSlip)
  let [, notify] = useNotifications()
  // Retry configuration for window opening
  let maxRetries = 3
  let retryDelay = 800 // milliseconds between retry attempts

  return async function onActionPrintAppointmentSlip({ value }) {
    try {
      let mutationResponse = await executeMutation({
        id: value.appointment_booking.vaxiom_id,
      })

      if (mutationResponse.error) {
        notify(notifyError('Something went wrong. Please, try again.'))
        return false
      }

      let signedURL = mutationResponse.data.vaxiom_appointment_request_slip.slip
      let response = await fetch(signedURL)

      if (!response.ok) {
        throw new Error(`Failed to fetch appointment slip: ${response.status}`)
      }

      let htmlContent = await response.text()
      let blob = new Blob([htmlContent], { type: 'text/html' })
      let blobUrl = URL.createObjectURL(blob)

      // Try to open the window with retry logic
      let newWindow = await tryOpenWindow(blobUrl, 0)

      if (!newWindow) {
        throw new Error('Could not open new window.')
      }

      newWindow.onload = () => {
        setTimeout(() => {
          newWindow.print()
        }, 1000)
      }

      newWindow.addEventListener('beforeunload', () => {
        URL.revokeObjectURL(blobUrl)
      })

      notify(notifySuccess(`Printed appointment slip.`))
      return true
    } catch (error) {
      console.error('Error printing appointment slip:', error)
      notify(notifyError('Failed to print. Please try again.'))
      return false
    }
  }

  /**
   * Function to try opening a window with retries
   * @param {string} url
   * @param {number} attempt
   * @returns
   */
  async function tryOpenWindow(url, attempt) {
    let newWindow = window.open(url, '_blank')

    // If successful, return the window
    if (newWindow) {
      return newWindow
    }

    // If we failed but haven't hit max retries, wait and try again
    if (attempt < maxRetries) {
      await new Promise(resolve => setTimeout(resolve, retryDelay))
      return tryOpenWindow(url, attempt + 1)
    }

    // Max retries reached without success
    return null
  }
}
