import { useEffect } from 'react'
import getPdfMake from 'Logic/pdfmake.js'
import { isAfter } from 'date-fns'
import {
  timeToMinutes,
  patientName,
  formatTimegridAppointmentTime,
} from 'Data/format.js'

/**
 * @typedef {{id: string, resourceId: string, start: number, end: number, startStr: string, endStr: string, extendedProps: Record<string, any>, splitEvent?: boolean, isFirstPart?: boolean, isSecondPart?: boolean}} Event
 * @typedef {{start: number, end: number}} Slot
 */

let PAGE_WIDTH = 841.89
let PAGE_HEIGHT = 595

/**
 * @param {{calendarRef: React.MutableRefObject<import('@fullcalendar/react').default | null>, hipaa: boolean, fileName: string}} param
 * @returns
 */
export default function useOnPrint({ calendarRef, hipaa, fileName }) {
  async function handleCustomPrintEvent() {
    let api = calendarRef?.current?.getApi()
    if (!api) {
      return
    }

    let pdfMake = await getPdfMake()

    let pdf = generateCalendarPDF(api, pdfMake, hipaa)
    if (pdf) {
      pdf.download(fileName)
    }
  }

  useEffect(() => {
    window.addEventListener('customBeforePrint', handleCustomPrintEvent)

    return () => {
      window.removeEventListener('customBeforePrint', handleCustomPrintEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hipaa])

  return {}
}

/**
 * Format a time value in minutes to display hours, minutes and AM/PM correctly
 * @param {number} timeInMinutes - Time in minutes (e.g., 720 for 12:00)
 * @returns {{hours: string, minutes: string, range: string}} Formatted time components
 */
function formatTimeComponents(timeInMinutes) {
  let hourValue = Math.floor(timeInMinutes / 60)
  let hour12 = hourValue % 12 === 0 ? 12 : hourValue % 12 // Handle 12 correctly
  let minutes = timeInMinutes % 60
  let ampm = hourValue >= 12 ? 'pm' : 'am' // 12pm is PM, not AM

  return {
    hours: hour12.toString(),
    minutes: minutes.toString().padStart(2, '0'),
    range: ampm,
  }
}

/**
 *
 * @param {import('@fullcalendar/core').CalendarApi} calendar
 * @param {import('pdfmake/build/pdfmake')} pdfmake
 * @param {boolean} hipaa
 * @returns
 */
function generateCalendarPDF(calendar, pdfmake, hipaa) {
  // Gather data from FullCalendar
  let resources = calendar.getResources()
  resources.sort((a, b) => a.extendedProps.position - b.extendedProps.position)

  // Calculate column widths
  let margin = 8
  let netWidth = PAGE_WIDTH - margin * 2 - 20
  let netHeight = PAGE_HEIGHT - margin * 2

  // Set time column width based on resource count
  let timeColWidth = resources.length > 10 ? 20 : resources.length > 6 ? 25 : 30
  let resourcesCount = resources.length
  let availableForResources = netWidth - timeColWidth
  let resourceColWidth = Math.floor(availableForResources / resourcesCount)
  let tableWidths = [
    timeColWidth,
    ...Array(resourcesCount).fill(resourceColWidth),
  ]

  // Grab slot config
  let slotMinTimeStr = calendar.getOption('slotMinTime')?.toString()
  let slotMaxTimeStr = calendar.getOption('slotMaxTime')?.toString()

  if (!slotMinTimeStr || !slotMaxTimeStr) {
    return
  }

  let slotMinTime = timeToMinutes(
    slotMinTimeStr.substring(0, slotMinTimeStr.lastIndexOf(':'))
  )
  let slotMaxTime = timeToMinutes(
    slotMaxTimeStr.substring(0, slotMaxTimeStr.lastIndexOf(':'))
  )
  let slotDuration = 5

  let events = calendar
    .getEvents()
    .map(event => {
      return {
        id: event.id,
        start: Math.max(
          timeToMinutes(
            event.startStr.substring(
              event.startStr.indexOf('T') + 1,
              event.startStr.lastIndexOf(':')
            )
          ),
          slotMinTime
        ),
        end: Math.min(
          timeToMinutes(
            event.endStr.substring(
              event.endStr.indexOf('T') + 1,
              event.endStr.lastIndexOf(':')
            )
          ),
          slotMaxTime
        ),
        startTime: event.start,
        endTime: event.end,
        startStr: event.startStr,
        endStr: event.endStr,
        resourceId: event.getResources()[0].id,
        display: event.display,
        extendedProps: event.extendedProps,
      }
    })
    .filter(
      ev =>
        ['appointment', 'note', 'blockTime'].includes(ev.extendedProps.type) &&
        ev.display !== 'background'
    )

  events.sort((a, b) => {
    // If types are the same, sort by startTime
    if (a.startTime === null) return 1
    if (b.startTime === null) return -1
    return isAfter(a.startTime, b.startTime) ? 1 : -1
  })

  // 2) Build an array of time slots (vertical axis)
  /** @type {Array<Slot>} */
  let slots = []
  for (let t = slotMinTime; t < slotMaxTime; t += slotDuration) {
    slots.push({ start: t, end: t + slotDuration })
  }

  events.sort((a, b) => {
    // Prioritize by type
    let typeOrder = { appointment: 1, note: 2, blockTime: 3 }

    // @ts-ignore
    let aTypePriority = typeOrder[a.extendedProps.type] || 4
    // @ts-ignore
    let bTypePriority = typeOrder[b.extendedProps.type] || 4

    return aTypePriority - bTypePriority
  })

  // ===== IMPROVED APPROACH: DIVIDE INTO TWO EQUAL PAGES =====

  // Calculate total time range in hours
  let totalHours = (slotMaxTime - slotMinTime) / 60

  // Calculate midpoint time - we'll always split at an hour or half-hour boundary
  let midpointHours = totalHours / 2
  let midpointMinutes = slotMinTime + midpointHours * 60

  // Round to nearest half hour for clean page break
  let remainder = midpointMinutes % 30
  if (remainder < 15) {
    midpointMinutes = midpointMinutes - remainder
  } else {
    midpointMinutes = midpointMinutes + (30 - remainder)
  }

  // Define exact page boundaries
  let pageBoundaries = [slotMinTime, midpointMinutes, slotMaxTime]

  // Process events to handle cross-page events
  /** @type {Array<Event>} */
  let processedEvents = []

  events.forEach(event => {
    // Check if event crosses the midpoint
    if (event.start < midpointMinutes && event.end > midpointMinutes) {
      // Split into two events - one for each page

      // Create ISO string for midpoint time
      // First extract the date part from the original startStr
      let datePart = ''
      if (event.startStr && event.startStr.includes('T')) {
        datePart = event.startStr.substring(0, event.startStr.indexOf('T'))
      }

      // Calculate hours and minutes for midpoint
      let midpointHours = Math.floor(midpointMinutes / 60)
      let midpointMins = midpointMinutes % 60

      // Format ISO string for midpoint time
      let midpointISOString = `${datePart}T${midpointHours
        .toString()
        .padStart(2, '0')}:${midpointMins.toString().padStart(2, '0')}:00`

      // Event for page 1 (before midpoint)
      let event1 = {
        ...event,
        id: `${event.id}-1`,
        end: midpointMinutes,
        endStr: midpointISOString,
        splitEvent: true,
        isFirstPart: true,
      }

      // Event for page 2 (after midpoint)
      let event2 = {
        ...event,
        id: `${event.id}-2`,
        start: midpointMinutes,
        startStr: midpointISOString,
        splitEvent: true,
        isSecondPart: true,
      }

      // Keep original ISO format if it exists
      if (event.startTime && typeof event.startTime === 'string') {
        event2.startTime = event.startTime
      }

      if (event.endTime && typeof event.endTime === 'string') {
        event1.endTime = event.endTime
      }

      processedEvents.push(event1, event2)
    } else {
      // Non-crossing event, add as is
      processedEvents.push(event)
    }
  })

  // This will store the content of the PDF
  /** @type {import('pdfmake/interfaces.js').Content} */
  let content = []

  // Generate exactly 2 pages
  for (let pageIndex = 0; pageIndex < 2; pageIndex++) {
    // Get the time range for this page
    let pageStartTime = pageBoundaries[pageIndex]
    let pageEndTime = pageBoundaries[pageIndex + 1]

    // Calculate slots for this page
    let startSlotIndex = Math.floor(
      (pageStartTime - slotMinTime) / slotDuration
    )
    let endSlotIndex = Math.ceil((pageEndTime - slotMinTime) / slotDuration)
    let pageSlotsCount = endSlotIndex - startSlotIndex

    // Calculate slot height for this page
    let slotHeight = (netHeight - 2 * pageSlotsCount) / pageSlotsCount

    // Filter events that belong on this page
    let pageEvents = processedEvents.filter(
      event => event.start < pageEndTime && event.end > pageStartTime
    )

    // Create table for this page
    /** @type {import('pdfmake/interfaces.js').TableCell[][]} */
    let tableBody = []

    // Add header row
    /** @type {import('pdfmake/interfaces.js').TableCell[]} */
    let headerRow = [
      { text: '', border: [false, true, true, true] },
      ...resources.map(r => {
        /** @type {import('pdfmake/interfaces.js').TableCell} */
        let cell = {
          margin: [2, 2, 2, 2],
          stack: [
            {
              text: r.extendedProps.name,
              width: resourceColWidth - 2,
              style: 'timeslotCell',
              bold: true,
            },
            {
              text:
                // @ts-ignore
                r.extendedProps.allocations.find(alloc => alloc.is_provider)
                  ?.name ?? 'No provider',
              width: resourceColWidth - 2,
              style: 'eventText',
            },
            {
              text:
                // @ts-ignore
                r.extendedProps.allocations.find(alloc => alloc.is_assistant)
                  ?.name ?? '-',
              width: resourceColWidth - 2,
              style: 'eventText',
              color: '#616e7c',
            },
          ],
        }
        return cell
      }),
    ]
    tableBody.push(headerRow)

    // Create rows for each time slot on this page
    /** @type {import('pdfmake/interfaces.js').TableCell[][]} */
    let rows = Array.from({ length: pageSlotsCount }).map(_ =>
      Array.from({ length: resources.length + 1 }).map(_ => ({ text: '' }))
    )

    // Fill in time slots
    for (let i = startSlotIndex; i < endSlotIndex; i++) {
      let slotIndex = i - startSlotIndex
      let currentSlot = slots[i]

      // Format the time for this slot
      let timeDisplay = formatTimeComponents(currentSlot.start)

      // Add time label to first column
      rows[slotIndex][0] = createSlotLabelCell({
        // Show hour when on the hour or first slot on page
        hours:
          currentSlot.start % 60 === 0 || i === startSlotIndex
            ? timeDisplay.hours
            : undefined,
        // Always show minutes
        minutes: timeDisplay.minutes,
        // Show am/pm when on the hour or first slot on page
        range:
          currentSlot.start % 60 === 0 || i === startSlotIndex
            ? timeDisplay.range
            : undefined,
      })
    }

    // Keep track of occupied cells
    let occupiedCells = new Set()

    // Place events on the page
    pageEvents.forEach(event => {
      // Find the resource index
      let resourceIndex = resources.findIndex(r => r.id === event.resourceId)
      if (resourceIndex === -1) return

      // Calculate event position on page
      let eventStartOnPage = Math.max(event.start, pageStartTime)
      let eventEndOnPage = Math.min(event.end, pageEndTime)

      // Convert to slot indices
      let eventStartSlot = Math.floor(
        (eventStartOnPage - pageStartTime) / slotDuration
      )
      let eventEndSlot = Math.ceil(
        (eventEndOnPage - pageStartTime) / slotDuration
      )

      // Calculate row span
      let rowSpan = eventEndSlot - eventStartSlot

      // Check if any slots are already occupied
      let canPlace = true
      for (let i = 0; i < rowSpan; i++) {
        let cellKey = `${eventStartSlot + i}-${resourceIndex}`
        if (occupiedCells.has(cellKey)) {
          canPlace = false
          break
        }
      }

      if (canPlace) {
        // Create the event cell with continuation marking based on split event
        let isContinuation = (event.splitEvent && event.isSecondPart) ?? false
        let willContinue = (event.splitEvent && event.isFirstPart) ?? false

        let cell = getCell({
          event,
          isContinuation,
          willContinue,
          rowSpan,
          cellWidth: resourceColWidth,
          slotHeight,
          hipaa,
        })

        // Place the cell
        rows[eventStartSlot][resourceIndex + 1] = cell

        // Mark cells as occupied
        for (let i = 0; i < rowSpan; i++) {
          let cellKey = `${eventStartSlot + i}-${resourceIndex}`
          occupiedCells.add(cellKey)
        }
      }
    })

    tableBody.push(...rows)

    // Add the table to content
    content.push({
      table: {
        heights: function (row) {
          if (row === 0) {
            // Header row - keep it at a reasonable fixed height
            return 36 // Fixed height for resource header cells
          }
          return slotHeight // Height for other rows
        },
        headerRows: 1,
        widths: tableWidths,
        body: tableBody,
      },
      layout: {
        hLineWidth: function (i, node) {
          return 1
        },
        vLineWidth: function (i, node) {
          return 1
        },
        hLineColor: function (i, node) {
          return '#E9ECEF'
        },
        vLineColor: function (i, node) {
          return '#E9ECEF'
        },
        paddingLeft: function (i, node) {
          return 0
        },
        paddingRight: function (i, node) {
          return 0
        },
        paddingTop: function (i, node) {
          return 0
        },
        paddingBottom: function (i, node) {
          return 0
        },
      },
      // Add pageBreak after first page
      ...(pageIndex === 0 ? { pageBreak: 'after' } : {}),
    })
  }

  /** @type {import('pdfmake/interfaces.js').TDocumentDefinitions} */
  let docDefinition = {
    pageOrientation: 'landscape',
    pageSize: 'A4',
    content,
    styles: {
      timeSlotCell: {
        fontSize: 6.5,
        color: '#9aa5b1',
      },
      eventText: {
        fontSize: 5.5,
        color: '#323f4b',
      },
      eventTextSecondary: {
        fontSize: 4.5,
        color: '#323f4b',
      },
    },
    pageMargins: margin,
    defaultStyle: {
      fontSize: 5.5,
    },
  }

  return pdfmake.createPdf(docDefinition)
}

/**
 * Simulate how text will render in available space, accounting for wrapping
 * @param {string} text - The text to fit
 * @param {number} availableWidth - Available width in PDF units
 * @param {number} maxLines - Maximum number of lines allowed
 * @param {number} fontSize - Font size in points
 * @param {string} fontType - Font type (normal, bold)
 * @returns {{lines: number, text: string[], fits: boolean}} - Resulting text data
 */
function calculateTextFit(
  text,
  availableWidth,
  maxLines,
  fontSize = 5,
  fontType = 'normal'
) {
  if (!text) return { lines: 0, text: [], fits: true }

  // Approximate character width based on font size
  let avgCharWidth = fontType === 'bold' ? fontSize * 0.65 : fontSize * 0.55

  // Split by words for wrapping simulation
  let words = text.split(' ')
  let lines = []
  let currentLine = ''
  let allContentFits = true

  for (let word of words) {
    let testLine = currentLine ? `${currentLine} ${word}` : word
    let testLineWidth = testLine.length * avgCharWidth

    if (testLineWidth <= availableWidth) {
      currentLine = testLine
    } else {
      if (currentLine) {
        lines.push(currentLine)
      } else {
        // Word is too long for a single line, need to truncate it
        let charsPerLine = Math.floor(availableWidth / avgCharWidth)
        lines.push(word.substring(0, charsPerLine - 3) + '...')
        allContentFits = false
        break
      }

      // Start new line with this word
      currentLine = word

      // Check if we've reached max lines
      if (lines.length >= maxLines - 1) {
        // We can't add any more lines after this one
        if (currentLine) {
          lines.push(
            currentLine + (words.indexOf(word) < words.length - 1 ? '...' : '')
          )
        }

        // There's more content that won't fit
        if (words.indexOf(word) < words.length - 1) {
          allContentFits = false
        }

        break
      }
    }
  }

  // Add the last line if there's room and content
  if (currentLine && lines.length < maxLines) {
    lines.push(currentLine)
  }

  // Check if we've hit our line limit but still have more words
  if (lines.length === maxLines && !allContentFits) {
    // Add ellipsis to the last line if it doesn't already have one
    let lastLine = lines[lines.length - 1]
    if (!lastLine.endsWith('...')) {
      lines[lines.length - 1] =
        lastLine.substring(0, lastLine.length - Math.min(3, lastLine.length)) +
        '...'
    }
  }

  return {
    lines: lines.length,
    text: lines,
    fits: allContentFits,
  }
}

/**
 * @param {{event: Event, isContinuation: boolean, willContinue: boolean, rowSpan: number, cellWidth: number, slotHeight: number, hipaa: boolean}} params
 * @returns {import('pdfmake/interfaces.js').TableCell}
 */
function getCell({
  event,
  isContinuation,
  willContinue,
  rowSpan,
  cellWidth,
  slotHeight,
  hipaa,
}) {
  // Define visual indicators for continuation
  let continuationPrefix = isContinuation ? 'CONT: ' : ''
  let continuationSuffix = willContinue ? ' (CONT)' : ''

  // Calculate cell height
  let cellHeight = rowSpan * slotHeight + Math.floor(1.1 * rowSpan)

  // Calculate available width for text (accounting for padding)
  let textAvailableWidth = cellWidth - 10 // Subtracting left and right padding

  // Calculate max lines that could fit in the available height
  let lineHeight = 6 // Approximate line height in points
  let verticalPadding = 4 // Top and bottom padding
  let maxLines = Math.max(
    1,
    Math.floor((cellHeight - verticalPadding) / lineHeight)
  )

  switch (event.extendedProps.type) {
    case 'appointment': {
      // Prepare text content
      let patientNameText = patientName({
        ...event.extendedProps.appointment.patient.person,
        last_name:
          !hipaa && event.extendedProps.appointment.patient.person.last_name,
      })

      let appointmentType = event.extendedProps.appointment.type.display_name

      let timeText = formatTimegridAppointmentTime({
        start: event.startStr,
        end: event.endStr,
      })

      // Apply continuation markers
      patientNameText =
        continuationPrefix + patientNameText + continuationSuffix

      // Calculate if all content would fit when properly wrapped
      let patientNameFit = calculateTextFit(
        patientNameText,
        textAvailableWidth,
        maxLines,
        5,
        'bold'
      )
      let linesRemaining = maxLines - patientNameFit.lines

      // Only calculate appointment type fit if we have room for it
      let appointmentTypeFit = null
      if (linesRemaining > 0) {
        appointmentTypeFit = calculateTextFit(
          appointmentType,
          textAvailableWidth,
          linesRemaining
        )
        linesRemaining -= appointmentTypeFit.lines
      }

      // Only calculate time fit if we have room for it
      let timeFit = null
      if (linesRemaining > 0) {
        timeFit = calculateTextFit(timeText, textAvailableWidth, linesRemaining)
      }

      // Build the stack content based on what fits
      let stackContent = []

      // Patient name is always included (potentially truncated)
      // The text field must be a string, not an array, for single-line text
      stackContent.push({
        text: Array.isArray(patientNameFit.text)
          ? patientNameFit.text.join(' ')
          : patientNameFit.text,
        style: 'eventText',
        margin: [0, 0, 0, 1],
      })

      // Add appointment type if it fits
      if (appointmentTypeFit && appointmentTypeFit.lines > 0) {
        stackContent.push({
          text: Array.isArray(appointmentTypeFit.text)
            ? appointmentTypeFit.text.join(' ')
            : appointmentTypeFit.text,
          style: 'eventText',
          margin: [0, 0, 0, 1],
        })

        // Add time if it also fits
        if (timeFit && timeFit.lines > 0) {
          stackContent.push({
            text: Array.isArray(timeFit.text)
              ? timeFit.text.join(' ')
              : timeFit.text,
            style: 'eventText',
            margin: [0, 0, 0, 0],
          })
        }
      }

      // Create a simplified outer structure with manual border settings
      return {
        rowSpan: rowSpan,
        margin: [0, 0, 0, 0], // No margin
        stack: [
          {
            // Use a canvas for the entire card background including color strip
            canvas: [
              // Main card background
              {
                type: 'rect',
                x: 2, // Shifted right to leave space for color strip
                y: 0,
                w: cellWidth - 4,
                h: cellHeight,
                r: 3,
                color: '#ffffff',
                lineColor: '#e0e0e0',
                lineWidth: 0.5,
              },
              // Left color strip
              {
                type: 'rect',
                x: 0,
                y: 0,
                w: 3,
                h: cellHeight,
                color: event.extendedProps.appointment_type_color,
                lineWidth: 0,
              },
            ],
          },
          // Text content
          {
            // Position text content with fixed positioning
            margin: [6, -cellHeight + 2, 2, 0],
            // Text stack with proper spacing
            stack: stackContent,
          },
        ],
      }
    }

    case 'note': {
      // Get note content
      let noteContent = event.extendedProps.content || ''

      // Apply continuation markers
      noteContent = continuationPrefix + noteContent + continuationSuffix

      // Calculate text fitting with wrapping
      let noteFit = calculateTextFit(noteContent, textAvailableWidth, maxLines)

      // Create a card-like appearance for notes
      return {
        rowSpan: rowSpan,
        margin: [0, 0, 0, 0], // No margin
        stack: [
          {
            // Use a canvas for the card background
            canvas: [
              // Note card background
              {
                type: 'rect',
                x: 0,
                y: 0,
                w: cellWidth - 2,
                h: cellHeight,
                r: 3,
                color: '#F8E3A3',
                lineColor: '#e9d28c',
                lineWidth: 0.5,
              },
            ],
          },
          // Note text
          {
            // Position text with fixed positioning
            margin: [4, -cellHeight + 2, 4, 0],
            stack: noteFit.text.map(line => ({
              text: line,
              style: 'eventText',
            })),
          },
        ],
      }
    }

    case 'blockTime': {
      // Get block time content
      let blockContent = event.extendedProps.content || ''

      // Apply continuation markers
      blockContent = continuationPrefix + blockContent + continuationSuffix

      // Calculate text fitting with wrapping
      let blockFit = calculateTextFit(
        blockContent,
        textAvailableWidth,
        maxLines
      )

      // Create a card-like appearance for block time
      return {
        rowSpan: rowSpan,
        margin: [0, 0, 0, 0], // No margin
        stack: [
          {
            // Use a canvas for the card background
            canvas: [
              // Block time card background
              {
                type: 'rect',
                x: 0,
                y: 0,
                w: cellWidth - 2,
                h: cellHeight,
                r: 3,
                color: '#CBD2D9',
                lineColor: '#b0b7bd',
                lineWidth: 0.5,
              },
            ],
          },
          // Block time text with multi-line support
          {
            // Position text with fixed positioning
            margin: [4, -cellHeight + 3, 4, 0],
            stack: blockFit.text.map(line => ({
              text: line,
              style: 'eventText',
            })),
          },
        ],
      }
    }

    default:
      return { text: '' }
  }
}

/**
 * @param {{hours?: string, range?: string, minutes: string}} params
 * @returns {import('pdfmake/interfaces').TableCell}
 */
function createSlotLabelCell({ hours, range, minutes }) {
  return {
    columns: [
      ...(hours
        ? [
            {
              text: hours,
              style: 'timeSlotCell',
              color: '#9aa5b1',
              bold: true,
              margin: [-3, 0, 1, 0],
            },
          ]
        : []),

      ...(range
        ? [
            {
              text: range,
              style: 'eventText',
              color: '#9aa5b1',
              bold: true,
              margin: [-1, 0, 1, 0],
            },
          ]
        : []),

      {
        text: minutes,
        style: 'eventText',
        color: '#7b8794',
        margin: [0, 0, 0, 0],
      },
    ],
    alignment: 'right',
    margin: [0, 0, 0, 0],
    border: [false, false, true, false],
  }
}
