import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'rename',
    viewPath: props.viewPath,
  })

  let treatment_plan_templates = useDataValue({
    context: 'treatment_plan_templates',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.treatment_plan_template_id = value
      next.treatment_plan_name = treatment_plan_templates.find(
        item => item.id === value
      )?.text
    })
  }
}
